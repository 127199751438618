<template>
<div>
    <v-container class="px-0">
        <v-col cols="12">
            <v-row>
                <v-col cols="12" lg="6" md="6">
                    <high-charts-pie
                        :id="1" 
                        :items="items"
                        :visibleSubTitle="true"
                        :subtitle="'CANT. CLIENTES POR PROYECTO'"
                        :name="'Proyecto'"
                        :valueSuffix="'UN.'"
                        :number="true"
                    />
                </v-col>
                <v-col cols="12" lg="6" md="6" class="mt-0">
                    <high-charts-pie
                        :id="2" 
                        :items="items2"
                        :visibleSubTitle="true"
                        :subtitle="'CANT. CLIENTES POR AÑO'"
                        :name="'Proyecto'"
                        :valueSuffix="'UN.'"
                        :number="true"
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</div>
</template>

<script>
import HighChartsPie from '@/components/Utils/HighCharts/HighChartsPie.vue';
export default {
    props: {
        items: {
            type: Array,
            default: []
        },
        items2: {
            type: Array,
            default: []
        }
    },
    components: {
        HighChartsPie
    },
    data() {
        return {

        };
    },
    methods: {},
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
